<template>
    <div>
        <!-- <TableSchedule></TableSchedule> -->

        <div v-if="main_package" class="my-6">
            <p class="font-semibold text-2xl uppercase text-center mb-12">
                {{ $t('page.package.current_plan') }}
            </p>

            <div
                class="component-box mx-auto max-w-[920px] flex shadow-md relative overflow-hidden"
            >
                <div
                    class="w-[400px] util-flex-center flex-col border-r-[1px] border-[#e1e7eb]"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-[60px] h-[60px] text-brand-atosa--light"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                        />
                    </svg>

                    <span
                        class="text-xl tracking-[1px] font-semibold text-brand-atosa--light mt-4 uppercase"
                    >
                        {{ main_package.package_data.name }}
                    </span>

                    <div
                        v-if="main_package && main_package.created_at"
                        class="mt-6 text-base"
                    >
                        <span
                            >{{ $t('page.package.register_from') }}:
                            &nbsp;</span
                        >
                        <span class="font-semibold">{{
                            (main_package.created_at * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}</span>
                    </div>

                    <div class="mt-2 text-base">
                        <span>{{ $t('page.package.license_to') }}: &nbsp;</span>
                        <span class="text-brand-atosa--light font-semibold">{{
                            (main_package.license_to * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}</span>
                    </div>

                    <div
                        v-if="custom_package"
                        class="bg-[#e1e7eb] w-2/3 my-6 h-[1px]"
                    ></div>

                    <div
                        v-if="custom_package"
                        class="flex items-center space-x-3"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="text-success w-10 h-10"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
                            />
                        </svg>

                        <div>
                            <p class="text-success font-semibold text-lg mb-2">
                                {{ custom_package.package_data.name }}
                            </p>
                        </div>
                    </div>

                    <div
                        v-if="custom_package && custom_package.created_at"
                        class="mt-4 text-sm"
                    >
                        <span class="font-semibold">{{
                            (custom_package.created_at * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}</span>
                        <span> - </span>
                        <span class="text-success font-semibold">{{
                            (custom_package.license_to * 1000)
                                | f__format_moment($t('common.format_fulldate'))
                        }}</span>
                    </div>
                </div>
                <div class="w-2/3">
                    <div class="py-2 pl-6 leading-8">
                        <div>
                            <i
                                class="el-icon-check text-brand-atosa font-semibold"
                            ></i
                            >&nbsp;
                            <span
                                v-html="
                                    $t(
                                        'page.package.manage_maximum_xxx_tiktok_accounts',
                                        {
                                            xxx: current_package.index
                                                .tiktok_account
                                        }
                                    )
                                "
                            ></span>
                        </div>
                        <div>
                            <i
                                class="el-icon-check text-brand-atosa font-semibold"
                            ></i
                            >&nbsp;
                            <span
                                v-html="
                                    $t(
                                        'page.package.manage_maximum_xxx_ads_accounts',
                                        {
                                            xxx: current_package.index
                                                .ad_account
                                        }
                                    )
                                "
                            ></span>
                        </div>

                        <div v-if="current_package.index.auto_ads > 0">
                            <i
                                class="el-icon-check text-brand-atosa font-semibold"
                            ></i
                            >&nbsp;
                            <span
                                v-html="
                                    $t('page.package.auto_optimize_xxx_ad', {
                                        xxx: current_package.index.auto_ads
                                    })
                                "
                            ></span>
                        </div>

                        <!-- <div class="text-base">
                            <p class="mt-4 font-medium">
                                {{ $t('common.extra') }}
                            </p>
                            <i
                                class="el-icon-check text-success font-semibold"
                            ></i
                            >&nbsp;
                            {{ $t('page.package.add_balance_easily_etc') }}
                            <br />

                            <i
                                class="el-icon-check text-success font-semibold"
                            ></i
                            >&nbsp; {{ $t('page.package.copy_campaign_etc') }}
                            <br />

                            <i
                                class="el-icon-check text-success font-semibold"
                            ></i
                            >&nbsp; {{ $t('page.package.detailed_analysis') }}
                        </div> -->

                        <div class="flex mt-2">
                            <el-button
                                type="primary"
                                size="small"
                                icon="el-icon-sort"
                                class="ml-auto"
                                @click="scrollToElement('pricing')"
                            >
                                {{ $t('page.package.change_plan') }}
                            </el-button>

                            <el-button
                                v-if="
                                    main_package &&
                                    custom_package_can_buy &&
                                    main_package.package_data.price > 0
                                "
                                type="success"
                                size="small"
                                icon="el-icon-menu"
                                class="ml-auto"
                                @click="scrollToElement('buy_more')"
                            >
                                {{ $t('page.package.buy_more') }}
                            </el-button>
                        </div>
                    </div>
                </div>

                <div
                    class="w-7 absolute top-0 left-0 h-full p-2 text-white"
                    :class="[expiry ? 'bg-brand-atosa--light' : 'bg-red']"
                >
                    <div class="util-flex-center w-full h-full">
                        <p
                            class="rotate-[270deg] text-sm min-w-max font-semibold"
                        >
                            {{
                                expiry
                                    ? $t('common.using')
                                    : $t('common.expire')
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="
                main_package &&
                custom_package_can_buy &&
                main_package.package_data.price > 0
            "
            id="buy_more"
            class="mt-[60px]"
        >
            <p class="font-semibold text-2xl uppercase text-center mb-12">
                {{ $t('page.package.buy_more') }}
            </p>

            <div class="component-box flex py-4">
                <div
                    class="util-flex-center space-x-8 px-12 border-r-[1px] border-[#e1e7eb]"
                >
                    <div class="util-flex-center flex-col">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="text-success w-10 h-10"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
                            />
                        </svg>
                        <p class="text-success text-xl font-semibold mt-2">
                            {{ custom_package_can_buy.name }}
                        </p>

                        <el-button
                            class="w-[200px] mt-4"
                            type="success"
                            @click="
                                buyPackage(custom_package_can_buy._id, true)
                            "
                        >
                            {{ $t('page.package.choose_plan') }}
                        </el-button>
                    </div>

                    <div class="util-flex-center flex-col">
                        <p class="text-3xl font-semibold text-success my-6">
                            {{
                                p__renderMoneyByCurrency(
                                    total_money_for_custom_package,
                                    s__currency
                                )
                            }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <el-input-number
                                v-model="custom_package_month"
                                size="small"
                                :min="1"
                            ></el-input-number>
                        </div>
                        <p class="mt-1 text-sm">
                            {{ $t('common.month') }}
                        </p>
                    </div>
                </div>

                <div class="flex-1">
                    <div class="flex items-center">
                        <div
                            v-for="(
                                item, index
                            ) in custom_package_can_buy.extra_fields"
                            :key="index"
                            class="flex-1 util-flex-center flex-col"
                        >
                            <p class="text-sm text-success font-semibold">
                                {{ getTitle(item.code) }}
                            </p>

                            <div
                                class="tablet:text-base desktop:text-2xl util-flex-center space-x-1 my-6"
                            >
                                <span
                                    class="text-xl font-semibold text-success"
                                >
                                    {{
                                        p__renderMoneyByCurrency(
                                            item.price,
                                            s__currency
                                        )
                                    }}
                                </span>
                            </div>

                            <el-input-number
                                v-model="item.number_1_month"
                                size="small"
                                :min="0"
                            ></el-input-number>
                            <p class="mt-1 text-sm">
                                {{ $t('common.quantity') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="pricing" class="mt-[60px]">
            <p class="font-semibold text-2xl uppercase text-center mb-12">
                {{ $t('page.package.our_best_pricing') }}
            </p>

            <div class="component-box py-4">
                <div
                    class="sticky top-0 left-0 bg-white pt-4 border-b-[1px] border-bg z-40"
                >
                    <div class="flex">
                        <div class="tablet:w-[240px] desktop:w-[360px]">
                            <p class="text-xl font-semibold">
                                {{ $t('common.feature') }}
                            </p>
                        </div>
                        <div
                            v-for="(item, index) in package_list"
                            :key="index"
                            class="flex-1 relative"
                        >
                            <div
                                v-if="item.extra_data.is_popular"
                                class="absolute top-[-52px] left-1/2 -translate-x-1/2 util-flex-center"
                            >
                                <div
                                    class="bg-warning py-2 px-6 rounded-xl w-max"
                                >
                                    <p class="font-semibold text-white text-sm">
                                        {{ $t('common.popular') }}
                                    </p>
                                </div>
                            </div>
                            <p
                                class="text-center text-2xl font-semibold"
                                :class="[
                                    item.is_extra
                                        ? 'text-success'
                                        : 'text-brand-atosa--light'
                                ]"
                            >
                                {{ item.name }}
                            </p>
                        </div>
                    </div>

                    <div class="flex py-6">
                        <div
                            class="tablet:w-[240px] desktop:w-[360px] invisible"
                        >
                            <p>Giá</p>
                        </div>
                        <div
                            v-for="(item_package, index_1) in package_list"
                            :key="index_1"
                            class="flex-1"
                        >
                            <div>
                                <p
                                    v-if="item_package.is_extra"
                                    class="text-center text-3xl font-semibold text-success"
                                >
                                    {{ $t('common.optional') }}
                                </p>
                                <p
                                    v-else-if="item_package.price === 0"
                                    class="text-center text-3xl font-semibold text-brand-atosa--light"
                                >
                                    {{ $t('common.free') }}
                                </p>
                                <div
                                    v-else
                                    class="text-base util-flex-center space-x-1"
                                >
                                    <span
                                        class="text-3xl font-bold text-brand-atosa--light"
                                    >
                                        {{
                                            p__renderMoneyByCurrency(
                                                item_package.price,
                                                s__currency
                                            )
                                        }}
                                    </span>

                                    <span class="font-medium lowercase">
                                        / {{ $t('common.month') }}</span
                                    >
                                </div>
                                <!-- <p
                                    class="text-center text-3xl font-semibold"
                                    :class="[
                                        item_package.is_extra
                                            ? 'text-success'
                                            : 'text-brand-atosa--light'
                                    ]"
                                >
                                    {{
                                        item_package.price === 0
                                            ? 'Miễn phí'
                                            : item_package.is_extra
                                            ? 'Tùy chỉnh'
                                            : item_package.price
                                    }}
                                </p> -->

                                <div class="px-3 mt-6">
                                    <el-button
                                        class="w-full"
                                        :type="
                                            item_package.is_extra
                                                ? 'success'
                                                : 'primary'
                                        "
                                        @click="buyPackage(item_package._id)"
                                    >
                                        {{
                                            item_package.price === 0
                                                ? $t('common.apply_package')
                                                : $t('page.package.choose_plan')
                                        }}
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-for="(extra_field, index) in extra_fields"
                    :key="index"
                    class="flex items-center py-4"
                    :class="[index % 2 === 0 ? 'bg-bg' : '']"
                >
                    <div
                        class="tablet:w-[240px] desktop:w-[360px] pl-2 relative"
                    >
                        <span>
                            {{ extra_field.title }}
                        </span>

                        <span v-if="extra_field.can_buy_more">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('page.package.can_buy_more')"
                                placement="top"
                            >
                                <i class="el-icon-circle-plus"></i>
                            </el-tooltip>
                        </span>
                    </div>
                    <div
                        v-for="(item_package, index_1) in package_list"
                        :key="index_1"
                        class="flex-1 text-center"
                    >
                        <p v-if="extra_field.is_boolean">
                            <span v-if="item_package.is_extra"></span>
                            <i
                                v-else-if="
                                    item_package.extra_data[extra_field.key]
                                "
                                class="el-icon-check text-success text-2xl"
                            ></i>
                            <i
                                v-else
                                class="el-icon-close text-red text-base"
                            ></i>
                        </p>

                        <p
                            v-else-if="
                                item_package.extra_data[extra_field.key] === 0
                            "
                        >
                            <i class="el-icon-close text-red text-báe"></i>
                        </p>
                        <p v-else class="text-lg font-semibold">
                            {{ item_package.extra_data[extra_field.key] }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentPackage } from '@/services/atosa/package'
import { getTiktokAdsPackages } from '@/services/atosa/package'
import moment from 'moment'
import { getPaymentAuto, updatePaymentAuto } from '@/services/atosa/payment'

export default {
    data() {
        return {
            custom_package_month: 1,
            custom_package_can_buy: null,
            package_list: [],
            extra_fields: [
                {
                    key: 'max_tiktok_account',
                    title: this.$t(
                        'page.package.extra_field.max_tiktok_account'
                    ),
                    can_buy_more: true,
                    is_boolean: false
                },
                {
                    key: 'max_ad_account',
                    title: this.$t('page.package.extra_field.max_ad_account'),
                    can_buy_more: true,
                    is_boolean: false
                },
                {
                    key: 'max_bc',
                    title: this.$t('page.package.extra_field.max_bc'),
                    can_buy_more: true,
                    is_boolean: false
                },
                {
                    key: 'auto_ads',
                    title: this.$t('page.package.extra_field.auto_ads'),
                    can_buy_more: true,
                    is_boolean: false
                },
                {
                    key: 'recharge_online',
                    title: this.$t('page.package.extra_field.recharge_online'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'recharge_to_ads_account',
                    title: this.$t(
                        'page.package.extra_field.recharge_to_ads_account'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'clone_campaign',
                    title: this.$t('page.package.extra_field.clone_campaign'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'analysis_data',
                    title: this.$t('page.package.extra_field.analysis_data'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'permission_second_account',
                    title: this.$t(
                        'page.package.extra_field.permission_second_account'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'manage_target',
                    title: this.$t('page.package.extra_field.manage_target'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'create_campaigns_in_1_time',
                    title: this.$t(
                        'page.package.extra_field.create_campaigns_in_1_time'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'edit_copy_campaign',
                    title: this.$t(
                        'page.package.extra_field.edit_copy_campaign'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'compare_data',
                    title: this.$t('page.package.extra_field.compare_data'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'analysis_hourly_data',
                    title: this.$t(
                        'page.package.extra_field.analysis_hourly_data'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'keyword_page',
                    title: this.$t('page.package.extra_field.keyword_page'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'manager_campaign_template',
                    title: this.$t(
                        'page.package.extra_field.manager_campaign_template'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'delete_campaign',
                    title: this.$t('page.package.extra_field.delete_campaign'),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'filter_campaign_by_conditions',
                    title: this.$t(
                        'page.package.extra_field.filter_campaign_by_conditions'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'auto_refresh_campaign',
                    title: this.$t(
                        'page.package.extra_field.auto_refresh_campaign'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'copy_adgroup_to_campaign',
                    title: this.$t(
                        'page.package.extra_field.copy_adgroup_to_campaign'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'internal_account_transfer',
                    title: this.$t(
                        'page.package.extra_field.internal_account_transfer'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'create_adgroup_by_campaign',
                    title: this.$t(
                        'page.package.extra_field.create_adgroup_by_campaign'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'report_by_ads_account',
                    title: this.$t(
                        'page.package.extra_field.report_by_ads_account'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'crud_target_when_campaign_run',
                    title: this.$t(
                        'page.package.extra_field.crud_target_when_campaign_run'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                },
                {
                    key: 'permission_target',
                    title: this.$t(
                        'page.package.extra_field.permission_target'
                    ),
                    can_buy_more: false,
                    is_boolean: true
                }
            ]
        }
    },

    computed: {
        s__currency() {
            return this.$store.getters.app.s__currency
        },

        s__channel() {
            return this.$store.getters.app.s__channel
        },

        s__language() {
            return this.$store.getters.app.s__language
        },

        main_package() {
            return this.$store.getters.currentPackage.s__main_package
        },

        custom_package() {
            return this.$store.getters.currentPackage.s__custom_package
        },

        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        },

        total_money_for_custom_package() {
            if (this.custom_package_can_buy) {
                return (
                    this.custom_package_can_buy.extra_fields.reduce(
                        (total, item) =>
                            total + item.number_1_month * item.price,
                        0
                    ) * this.custom_package_month
                )
            }
            return 0
        },

        expiry() {
            if (this.main_package) {
                const license_time = moment(this.main_package.license_to * 1000)

                return license_time.diff(moment()) > 0
            }

            return false
        }
    },

    mounted() {
        this.fetchTiktokAdsPackages()

        // this.getPaymentAuto()
    },

    methods: {
        async getPaymentAuto() {
            const response = await getPaymentAuto(this.s__channel)
            console.log(response)
        },

        getTitle(code) {
            if (code === 'tiktok_account') {
                return this.$t('common.tiktok_business')
            }
            if (code === 'ad_account') {
                return this.$t('common.advertiser_account')
            }
            if (code === 'max_bc') {
                return this.$t('common.business_center')
            }
            if (code === 'auto_ads') {
                return this.$t('common.automatic_rule')
            }
            return code
        },

        async buyPackage(package_id, is_custom = false) {
            let custom = ''
            try {
                if (is_custom) {
                    const data =
                        this.custom_package_can_buy.extra_fields.reduce(
                            (acc, curr) => {
                                acc[curr.code] = curr.number_1_month
                                return acc
                            },
                            {}
                        )

                    custom = `&extra=${btoa(JSON.stringify(data))}`
                }
                const url_redirect = encodeURIComponent(
                    `/payment/transfer?channel=${this.s__channel}&lang=${
                        this.s__language
                    }&package_id=${package_id}&quantity=${
                        is_custom ? this.custom_package_month : '6'
                    }&voucher_code=&trans_type=2&extra_data=${JSON.stringify({
                        channel: this.s__channel,
                        auto_apply: true
                    })}${custom}`
                )

                const url = `https://banhang.atosa.asia/authenticate/handle?token=${this.$store.state.user.s__user_info.token}&redirect=${url_redirect}`

                await this.p__OpenLinkOnNewWindow(url)

                this.checkCurrentPackage()
            } catch (error) {
                console.error(error)
            }
        },

        convertPriceToShortText(value) {
            if (value > 1_000_000_000) {
                return `${Number((value / 1_000_000_000).toFixed(1))}B`
            }
            if (value > 1_000_000) {
                return `${Number((value / 1_000_000).toFixed(1))}M`
            }
            return value
        },

        async fetchTiktokAdsPackages() {
            const response = await getTiktokAdsPackages(this.s__channel)
            this.package_list = response.data
                .sort((a, b) => a.extra_data.index - b.extra_data.index)
                .filter((item) => !item.is_extra)

            this.custom_package_can_buy = response.data.find(
                (item) => item.is_extra
            )

            if (this.custom_package_can_buy) {
                this.custom_package_can_buy.extra_fields = [
                    ...this.custom_package_can_buy.extra_fields
                ].map((item) => {
                    return {
                        ...item,
                        number_1_month: 0
                    }
                })
            }
        },

        scrollToElement(id) {
            location.href = '#' + id
        },

        async checkCurrentPackage() {
            try {
                const response = await getCurrentPackage(this.s__channel)
                if (response) {
                    const package_data = response.channel_packages.find(
                        (item) => item._id === response.license[0].package
                    )

                    await this.$store.dispatch(
                        'currentPackage/s__setCurrentPackage',
                        {
                            ...response,
                            extra_data: package_data
                                ? package_data.extra_data
                                : null
                        }
                    )
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
